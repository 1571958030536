import { checkout } from './app';

const axios = require('axios');

var baseUrl
var checkoutUrl
if(process.env.NODE_ENV === 'development') {
  baseUrl = 'https://app.powercrm.com.br/';
  checkoutUrl='https://crm.superbeneficios.com/'
} else if (process.env.NODE_ENV === 'test') {
  baseUrl = 'https://app.powercrm.com.br/';
} else {
  baseUrl = 'https://app.powercrm.com.br/';
  checkoutUrl = 'https://crm.superbeneficios.com/'
}

const pwr = axios.create({
  baseURL: baseUrl
})

const pwrCheckout = axios.create({
  baseURL: checkoutUrl
})


const TOKEN = window.localStorage.getItem('token')

export { pwr,pwrCheckout, TOKEN }
