import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import Modal from '../../Components/Modal/index';

import './Planos.scss';

import Carousel, { Dots } from '@brainhubeu/react-carousel';

import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import bgNaoGreenMobile from '../../images/nao-pedimos/bg-mobile1.svg'

import BgBanner from '../../images/planos/super-celular/banner-full2.png'
import LogoSuper from '../../images/planos/super-celular/logo-super.svg'

import IconNosso from '../../images/sobre/icon-nosso.svg'
import ArrowYellow from '../../images/icon/arrow-yellow.svg'

import ArrowBlue from '../../images/icon/arrow-blue.svg'
import bgNaoGreen from '../../images/nao-pedimos/bg-green.svg'
import bgNaoGreen2 from '../../images/nao-pedimos/bg-green2.svg'
import bgNaoPontos from '../../images/nao-pedimos/bg-pontos.svg'
import bgNaoYellow from '../../images/nao-pedimos/bg-yellow.svg'
import bgNaoYellowM from '../../images/nao-pedimos/bg-yellowM.svg'
import bgImage from '../../images/sobre/image-analise.png'

import Img2 from '../../images/planos/super-celular/img2.png'
import Bene from '../../images/planos/super-celular/cacc.svg'
import Descontos from '../../images/planos/super-celular/descontos.svg'
import Orientacao from '../../images/planos/super-celular/orientacao.svg'
import Prod1 from '../../images/produtos/moto.png'
import Prod3 from '../../images/produtos/carro.png'
import Prod6 from '../../images/planos/super-auto/image-suv.png'
import Prod8 from '../../images/produtos/carroseg.png'
import Quebra from '../../images/planos/super-celular/quebra.svg'
import Pecas from '../../images/planos/super-celular/pecas.svg'
import Derramamento from '../../images/planos/super-celular/derramamento.svg'

import icon1 from '../../images/planos/super-auto/icon1.svg'
import icon2 from '../../images/planos/super-auto/icon2.svg'
import icon3 from '../../images/planos/super-auto/icon3.svg'
import icon4 from '../../images/planos/super-auto/icon4.svg'
import icon5 from '../../images/planos/super-auto/icon5.svg'
import icon6 from '../../images/planos/super-auto/icon6.svg'
import icon7 from '../../images/planos/super-auto/icon7.svg'
import icon8 from '../../images/planos/super-auto/icon8.svg'
import icon9 from '../../images/planos/super-auto/icon9.svg'
import icon10 from '../../images/planos/super-auto/icon10.svg'
import icon11 from '../../images/planos/super-auto/icon11.svg'
import icon12 from '../../images/planos/super-auto/icon12.svg'

import Heart from '../../images/icon/coracao-garante.svg'

import ModalImage from '../../images/modal/image-modal.png'


export default function Index() {

    const [modalActive, setModalActive] = useState(false);
    const [modalFtActive, setModalFtActive] = useState(false);

    return (
        <div id="super-celular">
            <div id="banner">
                <img src={BgBanner} className="animated fadeInDown" />
                <div className="content">
                    <div className="row conteudos">
                        <div className="col-12">
                            <img className="img animated fadeInLeft" src={LogoSuper} />
                        </div>
                    </div> 
                </div>
            </div>

            
            <div id="produtoscel">
                <div className="content">
                    <div className="row">
                        <div className="col-12 row no-gutters">
                            <ScrollAnimation  className="col-lg-12 col-md-12" animateIn='fadeIn'>
                                <a href="/form-celular"  className="box">
                                    <div className='row'>
                                        <div className="col-md-6">
                                            <img src={Img2}></img>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='row'>
                                                <div className="col-12 text-center ">
                                                    <span className="f-weight-700 text-center  font-37 dp-bl">Plano Super  <span className='cl-blue'>Celular</span></span>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-12  text-center ">
                                                    <span className="text-center   cl-light-blue f-weight-700 font-16 dp-bl">Invista no cuidado do que é essencial e previna<br></br> grandes gastos futuros</span>
                                                </div>    
                                            </div>
                                            <div className='row '>
                                                <div className='col-sm-4 pt-8 mt-8'>
                                                     <img className="img " src={Quebra} /> 
                                                </div>
                                                <div className='col-sm-4 pt-8 mt-8'>
                                                <img className="img " src={Derramamento} /> 
                                                </div>
                                                <div className='col-sm-4 pt-8 mt-8'>
                                                <img className="img " src={Pecas} /> 
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-7 offset-2 text-center ">
                                                    <span className="text-center row cl-gray f-weight-700 font-16 dp-bl">Seu aparelho protegido por apenas</span>
                                                    <span className="cl-blue font-32 f-weight-700 font-p-mobal">R$ 9,90<span className="cl-gray font-16 f-weight-400">/mensais</span></span>
                                                    <p className="bg-light-blue cl-white bt font-18">
                                                            Cotação Online <img className="icon" src={ArrowYellow} />
                                                    </p>

                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                    
                            </ScrollAnimation>



                         </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div class="col-12" align="center">
                    <span class="    font-16">Conheça outros <b className="cl-blue"> Super Benefícios!</b></span>
                </div>
            </div>
            <div id="banner-novo">
                <div className="content">
                    <div className="row line-full line-bg no-gutters">
                        <div className="col-md-4">
                                            <img className='bene' src={Bene}></img>
                        </div>
                        <div className="col-md-7">
                        <div className='row'>
                                <div className='col-md-6'>
                                <img src={Descontos}></img>
                                </div>
                                <div className='col-md-6'>
                                <img src={Orientacao}></img>
                                </div>
                        </div>
                        <div className='row'>
                                <div className='col-md-7'>
                                <a href='super-vida'>
                                <p className="bg-blue cl-white bt font-8"><b>Quer saber mais?</b> Faça uma cotação online!</p>
                                    </a>

                                </div>
                                <div className='col-md-5 d-none'>
                                <p className="border-blue bt cl-blue font-18">Acessar o Super Vida</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>

 

            <BrowserView>
            <div id="solicite">
                <div className="content">
                    <div className="row">
                            <div className="col-lg-4 col-md-12 left-sl">
                                <ScrollAnimation animateOnce animateIn='fadeIn'>
                                    <img className="bg-slgrenn" src={bgNaoGreen} />
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce delay={250} animateIn='fadeIn'>
                                    <img className="bg-pontos" src={bgNaoPontos} />
                                </ScrollAnimation>
                                
                                <span className="cl-white font-64 f-weight-700">
                                    Não
                                    pedimos
                                    análise<br />
                                    de perfil.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação online <img className="icon" src={ArrowBlue} /></Link>
                            </div>

                        <div className="col-lg-8 col-md-12 left-sr left-srot">
                            <ScrollAnimation animateOnce animateIn='fadeIn'>
                                <img className="image" src={bgImage} />
                                <img className="bg-slyellow" src={bgNaoYellow} />
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn='fadeInRight'>
                                <img className="bg-green2" src={bgNaoGreen2} />
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
            </BrowserView>

            <MobileView>
            <div id="solicite">
                <div className="content">
                    <div className="row">

                        <div className="col-lg-8 col-md-12 left-sr left-srot">
                            <ScrollAnimation animateOnce animateIn='fadeIn'>
                                <img className="image image-sobre" src={bgImage} />
                                <img className="bg-slyellow" src={bgNaoYellowM} />
                            </ScrollAnimation>
                            <ScrollAnimation animateOnce animateIn='fadeInRight'>
                                <img className="bg-green2" src={bgNaoGreen2} />
                            </ScrollAnimation>
                        </div>
                        <div className="col-lg-4 col-md-12 left-sl">
                                <img className="bg-green-mobile" src={bgNaoGreenMobile} />
                                <img className="bg-pontos" src={bgNaoPontos} />
                                <span className="cl-white font-64 f-weight-700">
                                    Não
                                    pedimos<br />
                                    análise
                                    de perfil.
                                </span>
                                <Link onClick={(e) => setModalFtActive(true)} className="cl-blue border-yellow bt font-18">Solicite sua cotação online <img className="icon" src={ArrowBlue} /></Link>
                            </div>
                    </div>
                </div>
            </div>
            </MobileView>

            <Modal modalActive={modalFtActive}>
            <div id="modal-ini" className="bg-blue">
                <button onClick={(e) => setModalFtActive(false)} className="fechar"></button>
                <img src={ModalImage} />
                <BrowserView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <Link to="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</Link>
                        <Link to="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</Link>
                        <Link to="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</Link>
                        <Link to="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</Link>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <a href="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</a>
                        <a href="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</a>
                        <a href="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</a>
                        <a href="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</a>
                    </div>
                </MobileView>
            </div>

            <div id="line-garante">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="box-2">
                                <img src={Heart} />
                                <span className="cl-blue font-28 f-weight-700">Entenda como a Super<br /> garante seus benefícios</span>
                                <Link to="/super-garante" className="cl-blue border-blue bt">Saiba mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        </div>
    );
}
