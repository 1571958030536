import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import Modal from '../../Components/Modal/index';

import './Planos.scss';

import Carousel, { Dots } from '@brainhubeu/react-carousel';

import BgBanner from '../../images/planos/super-residencia/banner-full.png'
import LogoSuper from '../../images/planos/super-residencia/titulo.svg'

import BulletMais from '../../images/planos/super-vida/icon-plus3.svg'
import BulletMais1 from '../../images/planos/super-vida/icon-plus1.svg'
import BulletMais2 from '../../images/planos/super-vida/icon-plus2.svg'

import PlanoEssencial from '../../images/planos/super-residencia/super-essencial.svg'
import PlanoMais from '../../images/planos/super-residencia/super-mais.svg'
import PlanoPremium from '../../images/planos/super-residencia/super-premium.svg'

import Familia from '../../images/planos/super-residencia/familia.png'
import PointsBene from '../../images/planos/super-vida/points-bene.svg'

import IconNosso from '../../images/sobre/icon-nosso.svg'
import ArrowYellow from '../../images/icon/arrow-yellow.svg'

import ImageQualidade from '../../images/planos/super-vida/image-qualidade.png'
import ImageQualidadeMobile from '../../images/planos/super-vida/image-qualidade-mobile.png'

import ImageSuperAuto from '../../images/planos/super-vida/image-super-auto.svg'
import LogoSuperAuto from  '../../images/planos/super-vida/logo-super-auto.svg'

import ImageLink1 from '../../images/planos/super-vida/box-link1.png'
import ImageLink2 from '../../images/planos/super-vida/box-link2.png'

import ArrowBlue from '../../images/icon/arrow-blue.svg'
import bgNaoGreen from '../../images/nao-pedimos/bg-green.svg'
import bgNaoGreen2 from '../../images/nao-pedimos/bg-green2.svg'
import bgNaoPontos from '../../images/nao-pedimos/bg-pontos.svg'
import bgNaoYellow from '../../images/nao-pedimos/bg-yellow.svg'
import bgNaoYellowM from '../../images/nao-pedimos/bg-yellowM.svg'
import bgImage from '../../images/sobre/image-analise.png'

import Heart from '../../images/icon/coracao-garante.svg'

import icon1 from '../../images/planos/super-vida/icon1.svg'
import icon2 from '../../images/planos/super-vida/icon2.svg'
import icon3 from '../../images/planos/super-vida/icon3.svg'
import icon4 from '../../images/planos/super-vida/icon4.svg'
import icon5 from '../../images/planos/super-vida/icon5.svg'
import icon6 from '../../images/planos/super-vida/icon6.svg'
import icon7 from '../../images/planos/super-vida/icon7.svg'
import icon8 from '../../images/planos/super-vida/icon8.svg'
import icon9 from '../../images/planos/super-vida/icon9.svg'

import iconM1 from '../../images/planos/super-vida/iconM1.svg'
import iconM2 from '../../images/planos/super-vida/iconM2.svg'
import iconM3 from '../../images/planos/super-vida/iconM3.svg'
import iconM4 from '../../images/planos/super-vida/iconM4.svg'
import iconM5 from '../../images/planos/super-vida/iconM5.svg'
import iconM6 from '../../images/planos/super-vida/iconM6.svg'
import iconM7 from '../../images/planos/super-vida/iconM7.svg'
import iconM8 from '../../images/planos/super-vida/iconM8.svg'
import iconM9 from '../../images/planos/super-vida/iconM9.svg'

import ModalImage from '../../images/modal/image-modal.png'
import { BrowserView, MobileView } from 'react-device-detect';
import bgNaoGreenMobile from '../../images/nao-pedimos/bg-mobile1.svg'

export default function Index() {

    const [modalActive, setModalActive] = useState(false);
    const [modalFtActive, setModalFtActive] = useState(false);


    return (
        <div id="super-vida">
            <div id="banner">
                <img src={BgBanner} className="animated fadeInDown" />
                <div className="content">
                    <div className="row conteudos">
                        <div className="col-12">
                            <img className="img animated fadeInLeft" src={LogoSuper} width="489" />
                        </div>
                    </div> 
                </div>
            </div>

            <div id="planos">
            <div className="content">
                <div className="row cl-blue f-weight-700 font-24 titulo">
                   <b> Assistência Residencial</b>
                </div>
                <div className="row font-24">
                    Mais de <b>&nbsp;24 serviços oferecidos,&nbsp;</b> para cuidar bem da sua casa!
                </div>
                <div className="row font-24">
                   <b>
                   Conheça mais alguns dos benefícios
                   </b>
                </div>
                
            </div>
            </div>
        <div id="planos">
            <div className="content">
                <div className="row">

                    <div className="col-lg-4 col-md-12">
                        <div className="box bx1">
                            <div className="topo bg-p-blue1">
                                <img src={PlanoEssencial} />
                            </div>
                            <div className="list">
                            <span className="cl-gray"><img src={BulletMais1} />Chaveiro</span>
                            <span className="cl-gray"><img src={BulletMais1} />Eletricista</span>
                            <span className="cl-gray"><img src={BulletMais1} />Encanador</span>
                            <span className="cl-gray"><img src={BulletMais1} />Vidraceiro</span>
                            </div>
                            <div className="footer">
                                <p className="font-14 cl-gray f-wheight-700">Economia e tranquilidade por apenas</p>
                                <span className="cl-blue font-32 f-wheight-700 valor">R$ 29,90<span className="font-16 cl-gray f-wheight-500">/mês</span></span>
                                <Link to="form-casa" className="bt f-wheight-600  bg-p-blue1 cl-white font-18">Cotação Online <img src={ArrowYellow} /></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="box bx2">
                            <div className="topo bg-p-blue2">
                                <img src={PlanoMais} />
                            </div>
                            <div className="list">
                            <span className="cl-gray"><img src={BulletMais2} />Chaveiro</span>
                            <span className="cl-gray"><img src={BulletMais2} />Cobertura Provisória de Telhado</span>
                            <span className="cl-gray"><img src={BulletMais2} />Desentupimento de Tubulação de Esgoto ou Caixa de Gordura</span>
                            <span className="cl-gray"><img src={BulletMais2} />Eletricista</span>
                            <span className="cl-gray"><img src={BulletMais2} />Encanador</span>
                            <span className="cl-gray"><img src={BulletMais2} />Transmissão de Mensagens Urgentes</span>
                            <span className="cl-gray"><img src={BulletMais2} />Vidraceiro</span>
                            <span className="cl-gray"><img src={BulletMais2} />Vigilância</span>
                            <span className="cl-gray"><img src={BulletMais2} />Serviços de Informação</span>
                            <span className="cl-gray"><img src={BulletMais2} />Substituição de Telhas</span>
                            </div>
                            <div className="footer">
                                <p className="font-14 cl-gray f-wheight-700">Economia e tranquilidade por apenas</p>
                                <span className="cl-blue font-32 f-wheight-700 valor">R$ 34,90<span className=" font-16 cl-gray f-wheight-500">/mês</span></span>
                                <Link to="form-casa" className="bt f-wheight-600  bg-p-blue2 cl-white font-18">Cotação Online <img src={ArrowYellow} /></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="box last-box bx3">
                            <div className="topo bg-p-blue3">
                                <img src={PlanoPremium} />
                            </div>
                            <div className="list">
                            <span className="cl-gray"><img src={BulletMais} />Chaveiro</span>
                            <span className="cl-gray"><img src={BulletMais} />Cobertura Provisória de Telhado</span>
                            <span className="cl-gray"><img src={BulletMais} />Desentupimento de Tubulação de Esgoto ou Caixa de Gordura</span>
                            <span className="cl-gray"><img src={BulletMais} />Eletricista</span>
                            <span className="cl-gray"><img src={BulletMais} />Encanador</span>
                            <span className="cl-gray"><img src={BulletMais} />Transmissão de Mensagens Urgentes</span>
                            <span className="cl-gray"><img src={BulletMais} />Vidraceiro</span>
                            <span className="cl-gray"><img src={BulletMais} />Vigilância</span>
                            <span className="cl-gray"><img src={BulletMais} />Serviços de Informação</span>
                            <span className="cl-gray"><img src={BulletMais} />Substituição de Telhas</span>
                            <span className="cl-gray"><img src={BulletMais} />Hospedagem</span>
                            <span className="cl-gray"><img src={BulletMais} />Regresso Antecipado</span>
                            <span className="cl-gray"><img src={BulletMais} />Regresso Antecipado em caso de falecimento de parente</span>
                            <span className="cl-gray"><img src={BulletMais} />Acompanhamento ao Usuário Hospitalizado-Transporte para Familiar</span>
                            <span className="cl-gray"><img src={BulletMais} />Baby-Sitter </span>
                            <span className="cl-gray"><img src={BulletMais} />Guarda de Animais Domésticos</span>
                            <span className="cl-gray"><img src={BulletMais} />Guia de Profissionais Orçamentistas</span>
                            <span className="cl-gray"><img src={BulletMais} />Limpeza da Residência</span>
                            <span className="cl-gray"><img src={BulletMais} />Mudança e Guarda de Móveis</span>
                            <span className="cl-gray"><img src={BulletMais} />Remoção Inter Hospitalar</span>
                            <span className="cl-gray"><img src={BulletMais} />Restaurante e Lavanderia</span>
                            <span className="cl-gray"><img src={BulletMais} />Serviços Domésticos Provisórios - Faxineira</span>
                            <span className="cl-gray"><img src={BulletMais} />Serviços de Despachante</span>
                            <span className="cl-gray"><img src={BulletMais} />Chaveiro Conveniência</span>
                            <span className="cl-gray"><img src={BulletMais} />Limpeza de Caixa D'Água</span>
                            <span className="cl-gray"><img src={BulletMais} />Reparo de Ar Condicionado</span>
                            <span className="cl-gray"><img src={BulletMais} />Reparo em Portão Automático</span>
                            </div>
                            <div className="footer">
                                <p className="font-14 cl-gray f-wheight-700">Economia e tranquilidade por apenas</p>
                                <span className="cl-blue font-32 f-wheight-700 valor">R$ 39,90<span className="font-16 cl-gray f-wheight-500">/mês</span></span>
                                <Link to="form-casa" className="bt f-wheight-600  bg-p-blue3 cl-white font-18">Cotação Online <img src={ArrowYellow} /></Link>
                            </div>
                        </div>
                    </div>
                    <BrowserView>
                        <img className="familia-residencia" src={Familia} />
                    </BrowserView>
                </div>
            </div>
        </div>

            <Modal modalActive={modalFtActive}>
            <div id="modal-ini" className="bg-blue">
                <button onClick={(e) => setModalFtActive(false)} className="fechar"></button>
                <img src={ModalImage} />
                <BrowserView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <Link to="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</Link>
                        <Link to="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</Link>
                        <Link to="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</Link>
                        <Link to="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</Link>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="buttons">
                        <a href="/form-auto" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Auto</a>
                        <a href="/form-saude" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Saúde</a>
                        <a href="/form-casa" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Residência</a>
                        <a href="/form-pet" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Pet</a>
                        <a href="/form-dental" onClick={(e) => setModalFtActive(false)} className="font-16 cl-white border-white bt">Super Odonto</a>
                    </div>
                </MobileView>
            </div>

            <div id="line-garante">
                <div className="content">
                    <div className="row">
                        <div className="col-12">
                            <div className="box-2">
                                <img src={Heart} />
                                <span className="cl-blue font-28 f-weight-700">Entenda como a Super<br /> garante seus benefícios</span>
                                <Link to="/super-garante" className="cl-blue border-blue bt">Saiba mais</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        </div>
    );
}
