import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import './Form.scss';

import swal from 'sweetalert';
import { FiLoader } from 'react-icons/fi';

import { api, checkout } from '../../Api/app'
import { pwrCheckout, pwr  } from '../../Api/pwr'


import ArrowYellow from '../../images/icon/arrow-yellow.svg'

import IconS1 from '../../images/forms/Icons-1.svg'
import IconS2 from '../../images/forms/Icons-2.svg'
import IconS3 from '../../images/forms/Icons-3.svg'
import IconS4 from '../../images/forms/Icons-4.svg'
import IconS5 from '../../images/forms/Icons-5.svg'
import IconS6 from '../../images/forms/Icons-6.svg'
import IconS7 from '../../images/forms/Icons-7.svg'
import IconS8 from '../../images/forms/Icons-8.svg'
import IconS9 from '../../images/forms/Icons-9.svg'
import Image from '../../images/forms/image-pet.png'
import Logo from '../../images/forms/logo-pet.svg'

import ImagePetMobile from '../../images/forms/image-pet-mobile.png'

import InputMask from 'react-input-mask';
import { BrowserView, MobileView } from 'react-device-detect';


export default function Index() {

    const [stepLoad, setstepLoad] = useState(false)
    const [camposFormulario, setCamposFormularios] = useState({
        nome: '',
        telefone: '',
        email: '',
        mensagem: '',
        plano: '',
    })


    async function insertPasso1(){

        let slsmnId = null;
        let slsmnNwId = null;
        swal({
            title: ' ',
            text: '"Enviado com sucesso, você será direcionado ao checkout para concluir sua proteção!',
            showCancelButton: false,
            showConfirmButton: false
          })

        let response =  await pwrCheckout.post('/qttnStep1?plano='+camposFormulario.plano,{
            h: 'sp beneficios507',
            slsmnId: slsmnId,
            slsmnNwId: 'NrP2PKql',
            name: camposFormulario.nome,
            email: camposFormulario.email,
            phone: camposFormulario.telefone
        });

        
        window.location.href = 'https://crm.superbeneficios.com/checkout?h='+response.data.back;

    
    }



    async function handleSend(event)
    {

        event.preventDefault()

        var data = camposFormulario

        setstepLoad(true)

             var response =  await api.post('/form-pet', data)

            if  ( response.data.error == true){
                alert( response.data.message )
            }  else {
                swal("Enviado com sucesso!");
            }

            setTimeout(function(){
                setstepLoad(false)
            })

        

        console.log( response )

    }

    function changeInputMult(e)
    {

        var meusdados = camposFormulario
        meusdados[e.target.name] = e.target.value
        setCamposFormularios({...meusdados})

        console.log( meusdados )

    }

    return (
        <div id="credenciados">
            <div id="head"></div>
            <div id="formg">
                <div className="content">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 left-form" >
                            <div className="text">
                                <div className="breve-pet bg-yellow cl-black font-16 f-weight-700 form-dpn">
                                    <span>Em <br />
                                    breve!</span>
                                </div>
                                <BrowserView>
                                    <span className="txt font-32 cl-white">
                                        Todos os 
                                        membros da 
                                        família<br /> 
                                        merece o melhor!<br />
                                        <b className="font-weight-700">Os de 4 patas também!</b>
                                    </span>
                                </BrowserView>
                                <MobileView>
                                    <span className="txt font-32 cl-white font-weight-700">
                                        Todos os
                                        membros da<br />
                                        família merece
                                        o melhor!<br />
                                        <b>Os de 4 patas
                                        também!</b>
                                    </span>
                                </MobileView>
                            </div>
                            <MobileView>
                                <img className="image-pet-mobile" src={ImagePetMobile} />
                            </MobileView>
                            <BrowserView>
                                <img className="image-pet" src={Image} />
                            </BrowserView>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="box-form row no-gutters form animated fadeIn">
                                <div className="col-12 row no-gutters infos">
                                    <img src={Logo} />
                                    <p font-16> 
                                    A qualidade de vida que sua família merece não precisa custar caro!<br />
                                        <b className="cl-light-blue">Não fazemos análise de Perfil.</b>
                                    </p>
                                </div>
                                <div className="col-12 row no-gutters">
                                    <div className="col-12  row form-gen  no-gutters" method="post" >
                                    <div className="col-12">
                                            <select name="plano" required onChange={(e) => changeInputMult(e)} >
                                                <option value="">Plano</option>
                                                <option value="PLANO_PETBASICO">Super PET Essencial | 39,90/mensais</option>
                                                <option value="PLANO_PETPLUS">Super PET + | 79,90/mensais</option>
                                                <option value="PLANO_PET_PREMIUM">Super PET Premium | 199,90/mensais</option>
                                            </select>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" required value={camposFormulario.nome} onChange={(e) => changeInputMult(e)} name="nome" placeholder="Nome completo"/>
                                        </div>

                                        <div className="col-12">
                                        <InputMask mask="(99) 99999-9999" required  type="text" value={camposFormulario.telefone} onChange={(e) => changeInputMult(e)} name="telefone" placeholder="Telefone" />
                                        </div>
                                        
                                        <div className="col-12" align="right">
                                            <input type="text" required name="email" value={camposFormulario.email} onChange={(e) => changeInputMult(e)} placeholder="E-mail" />
                                        </div>

                                                            
                                        
                                        <div className="ipt-check col-12">
                                            <button type="submit"  onClick={() => insertPasso1()}>{stepLoad === true ? <FiLoader /> : "Quero mais informações"} <img src={ArrowYellow} /> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
